<template>
    <div class="fixed_box" v-if="loading">
      <div class="box_center" @click="loadData">
        <div>加载失败</div>
      </div>
    </div>
    <div class="fixed_box" v-else-if="isRegister">
        <div class="box_center" @click="goRegister">
            <div>您是未注册用户，请点击
              <span class="color66">注册</span>
            </div>
        </div>
    </div>
    <div v-else>
      <!-- 弹窗 -->
        <div class='rulePopView' v-if="isNew">
          <div class='ruleDesView'>
          <div class='ruleDesImage'>
            <div class="tipTitle">
              提示
            </div>
            <div class="tipNav">
            <div class="margin20">1.调节字体</div>
            <div class="textcenter">两指缩放屏幕，可调整页面字体大小。</div>
            <div class="margin20">2.收藏文章</div>
            <div class="textcenter">长按屏幕1秒出现工具台，可进行文章收藏。</div>
            </div>
            
          </div>
          </div>

          <div class='cancelView' @click="ruleDesHiddenAction">
          <img class='cancelImage' src="http://weixin.siyanli.net.cn/static/miniimg/guanbichuangkou.png" />
          </div>
        
        </div>
        <!-- 渲染pdf -->
        <div v-longpress="longpress" @click="clickWrap" >
          <!-- <div>{{intPageATime}}</div> -->
          <!-- <div v-if="loading == 0"> -->
          <div class="page-pdf-v" v-for="(page,index) in pageList" v-bind:key="index">
            <!-- loading -->
            <van-loading class="my-loading" color="#ff4646" type="spinner" v-show="index===0"  size="24px">总共{{pageList.length}}页</van-loading>
            <vue-pdf
              :src="src"
              class="vue-pdf"
              :page="page"
            >
            </vue-pdf>
          </div>
          <van-popup
            v-model="settingShow"
            @click.stop="clickPopup"
            position="bottom"
            class="van-popup bg-white"
            :style="{ boxShadow: '0px -2px 6px #eeeeee' }"
            :lock-scroll="false"
            overlay-class="popup-mask"
          >
            <div class="set-bar">
              <!-- <div class="flex-center" @click.stop="changePdfPage(0)">
                <van-icon name="arrow-left" size="24px" />
                <span class="page-text">上一页</span>
              </div> -->
              <van-icon
                class="iconfont"
                class-prefix="icon"
                v-if="~~data.isCollect === 0"
                name="shoucang"
                @click.stop="addFavorite"
              />
              <van-icon
                class="iconfont"
                class-prefix="icon"
                v-if="~~data.isCollect === 1"
                name="xingzhuanggongnengtubiao"
                @click.stop="addFavorite"
              />
              <van-icon
                class="iconfont"
                class-prefix="icon"
                name="fenxiang"
                @click.stop="postShraMessage"
              />
              <!-- <div class="flex-center" @click.stop="changePdfPage(1)">
                <span class="page-text">下一页</span>
                <van-icon name="arrow" size="24px" />
              </div> -->
            </div>
          </van-popup>
          <!-- </div> -->
          
          
        </div>
    </div>
    
</template>

<script>
import wx from "weixin-js-sdk";
import pdf from "vue-pdf";
import { Icon, Toast, Popup,Dialog,Loading } from "vant";
// import { Base64 } from "../utils/base64.js";
export default {
  components: {
    "van-popup": Popup,
    "vue-pdf": pdf,
    "van-icon": Icon,
    "van-loading": Loading
  },
  data() {
    return {
      data: {},
      src: "",
      loadedRa: 0,
      currentPage: 1,
      pageCount: 0,
      settingShow: false,
      userId:'',//小程序的userId
      pageList: [],
      loading:false,
      query:null,
      isRegister:false, //未登录
      isNew:false,//是否为第一次查看
    };
  },
  watch:{
    currentPage(){
      // let innerWididth = window.innerWidth; //屏幕宽
      // let contentWidth = canvas.width; //canvas宽
      // if(contentWidth > innerWididth){
      //   this.settingShow = false;
      // }
    }

  },
  mounted() {
    this.loadData();

  },
  methods: {
    goRegister(){
      // 点击跳转小程序页面
      wx.miniProgram.navigateTo({
          url: '/pages/logup/index'
      })
    },
    longpress() {
      // Toast("长按");
      this.settingShow = true;
    },
    clickWrap() {
      this.settingShow = false;
    },
    //会员申请
    sendVipQue(){
      const options = {
        method: "POST",
        url: this.$api.sendVipQue,
        data: {userId: this.$route.query.userId}
      };
      this.$http(options).then(res => {
        console.log(res,"会员申请")
        Toast(res.data.msg)
        if (res.data.code === 200) {
          setTimeout(()=> {
            this.loadData()
          },1000)
        }
      })
    },
    //数据请求
    loadData() { 

      let id = this.$route.query.id;//期刊id
      let articleId = this.$route.query.articleId; //文章id
      let userid = this.$route.query.userId;//用户id
      this.userId = this.$route.query.userId;

      let query = "?id=" + id + "&articleId=" + articleId
      console.log(query,"query");
      const options = {
        method: "GET",
        url: this.$api.mgetPdf + query
      };
      this.$http(options).then(res => {
        console.log(res,"pdf阅读");
        if (res.data.code === 200 && res.data.data) {
         
            this.loading = false; //有数据就为false
            this.data = res.data.data;
            this.src = this.$BASE + res.data.data.url;
            this.currentPage = res.data.data.beginPage; // 加载的时候先加载第一页
            for(let index = this.data.beginPage;index<=this.data.endPage;index++){
              this.pageList.push(index)
            }
            if(res.data.data.isNew == 1){ //isNew  1: 是  (第一次) 0: 否
              this.isNew = true;
            }

          // console.log(this.pageList)
        } else if (res.data.code === 667) {
          // this.showVip = true
          // this.tipMsg = res.data.msg
          Dialog.confirm({ 
            message: res.data.msg,
            confirmButtonText: "申请会员",
            showCancelButton: false,
            beforeClose:(action, done) => {
              this.sendVipQue()
              done()
            },
          });
        }else if(res.data.code === 500){
          this.loading = true; //无数据就为true
        }else if(res.data.code === 660){
          console.log("未注册用户")
          this.isRegister = true;
        } else {
          Dialog({ 
            message: res.data.msg,
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
      }).catch(
          res => {
              alert(res)
              console.log('error data::', res);
              this.loading = true; //无数据就为true
          }
      );
    },
    //关闭弹窗
    ruleDesHiddenAction(){
    console.log("点击了关闭")
    var that = this;
    that.isNew=false;
    // that.loadData();
 },
   
    addFavorite(){
      let id = this.$route.query.articleId;//改为文章id  3/4 qylupdata
      let userid = this.$route.query.userId;
      let type = ~~this.data.isCollect==1?0:1;
      // let query = "?articleId="+id+"&userId="+userid+"&type="+type
      const options = {
        method: "POST",
        url: this.$api.setCollect,
        data: {articleId:id,userId:userid,type:type}
      };
      this.$http(options).then(res => {
        console.log(res);
        if (res.data.code === 200) {
          this.data.isCollect = ~~this.data.isCollect===1?0:1
          let msg = type===1?"已收藏":"已取消"
          Toast(msg);
        }else{
          Toast(res.data.msg)
        }
      });
    },
    postShraMessage(){
      // let query = "type=html&id="+ this.$route.query.id;
      let query = "type=pdf&id="+ this.$route.query.id;
      alert("触发了");
      wx.miniProgram.postMessage({
        title: this.data.author,
        desc:this.data.title,
        path: "/pages/HTMLreader/index" + query,
      })
    },
  }
};
</script>

<style scoped>
.vue-pdf{
  width: 100vw;
  position: relative;
}
.van-popup {
  min-height: 8%;
  /* box-shadow: 0px -2px 10px #ececec; */
}
.bg-white {
  background-color: #ffffff;
  color: #292929;
}
/deep/ .van-overlay{
  background: none !important;
  height: 0px !important;
}
.popup-mask {
}
.set-bar {
  display: flex;
  flex: 1;
  height: 50px;
  align-items: center;
  justify-content: space-around;
}
.flex-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.page-text {
  font-size: 14px;
}
.fixed_box{
  position: fixed;
    top: 30%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    text-align: -webkit-center;
}
.box_center{
  text-align: -webkit-center;
    display:inline-block;
    /* width:100px;
    height: 60px;
    text-align: center; */
}
.color66{
  color: #66ccff;
}
/* 活动规则弹框 */
.rulePopView {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999;
}
.ruleDesView {
  top: 20%;
  right: 15%;
  left: 15%;
  height:50%;
  opacity: 1;
  background-color: white;
  position: fixed;
  width: 70%;
  border-radius: 0.1rem;
}
.ruleDesImage {
  width: 100%;
  height: 100%;
  
}
.cancelView {
  border-radius: 0.1rem;
  top: 65%;
  right:46%;
  left: 46%;
  position: absolute;
}
.cancelImage {
  width: 100%;
  height: 100% ;
}
.tipTitle{
  text-align: center;
  font-size: 0.35rem;
  height: 1rem;
  line-height: 1rem;
  font-weight: bold;
  background-color: #58ACED;
  color: #ffffff;
}
.tipNav{
  padding:5%;
  font-size: 0.5rem;
  margin-top:10px;
}
.textcenter{
  font-size: 0.4rem;
  margin-top: 0.1rem;
}
.margin20{
  margin-top:20px;
}
image{
  overflow:visible;
}
.my-loading{
  width: 100vw;
  height: 500px;
  position: absolute;
  top: 100px;
  left: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-pdf-v{
  position: relative;
}
</style>
