<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// const VConsole = require("./vconsole.min.js");
export default {
  mounted() {
    // let vConsole = new VConsole();
    // console.log('使用了vconsole',vConsole)
    // 最低 ios 13 版本
    // if (window.navigator.userAgent.toLowerCase().indexOf("iphone os 12") !== -1) {
    //   this.$router.replace('/')
    // }
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
.popup-mask {
  background: none !important;
}
figure {
  display: block;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
} /* 特殊属性需要设置默认值 */
img {
  width: 100%;
}
image {
  overflow: visible;
}
</style>
