<template>
  <div class="fixed_box">
   <div :class="wrapClass" v-longpress="longpress" @click="clickWrap">
       <!-- <div v-if="loading == 0"> -->
       <div :class="titleClass">{{ data.title }}</div>
       <div :class="assistClass">{{ data.subtitle }}</div>
       <div :class="authorClass" v-if="data.author">作者：{{ data.author }}</div>
       <div v-html="data.content" :class="contentClass"></div>

       <!-- 找到相关文章的 id  -->
       <!-- <div :class="articleitemClass" v-for="(article,index) in data.articleList" v-bind:key="index">
             {{article.id}}
           </div> -->
   
       <!-- </div> -->
     </div>
   
   </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast, Popup, Icon, Slider, Dialog } from "vant";
// import { Base64 } from "../utils/base64.js";
// import { constants } from 'zlib';
// import { quillEditor } from "vue-quill-editor"; //引用富文本和富文本的样式
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
import axios from "axios";
export default {
  components: {
    "van-popup": Popup,
    "van-icon": Icon,
    "van-slider": Slider,
    // "van-dialog": Dialog,
  },
  data() {
    return {
      data: {},
      settingShow: false,
      bgColor: "white", // coral  palegreen  black
      wrapClass: { wrap: true, "bg-white": true },
      lineClass: { "line-white": true },
      popupClass: { "van-popup": true, "bg-white": true },
      textFont: 16, //10~20  step 2
      titleClass: { title: true, "font-20": true },
      assistClass: { assist: true },
      authorClass: { author: true },
      othertitleClass: { "other-title-v": true, "font-20": true },
      contentClass: { "font-16": true, content: true, widthImg: true },
      articleitemClass: { "articleList-item": true, qyl_font16: true }, //qylupdata 3/4 字体行高
      setType: "font", //bg
      tipMsg: "",
      showVip: false,
      fixed_box: { fixed_box: true },
      box_center: { box_center: true },
      loading: false,
      query: null, //参数
      isRegister: false, //未登录
      isNew: false, //是否是第一次阅读
    };
  },
  watch: {
    bgColor: function(val) {
      let name1 = "bg-" + val;
      let name2 = "line-" + val;
      this.wrapClass = { wrap: true, [name1]: true };
      this.lineClass = { [name2]: true };
      this.popupClass = { "van-popup": true, [name1]: true };
      this.fixed_box = { fixed_box: true };
      (this.fixed_box = { fixed_box: true }),
        (this.box_center = { box_center: true });
    },
    textFont: function(val) {
      let v = Math.round(val); //四舍五入
      let name1 = "font-" + v;
      let name2 = "font-" + (v + 4);
      // let name3 = 'font-'+ (val==10?val:val-2);
      // let name3 = 'font-'+ (v==10?v:v-1);
      this.contentClass = { [name1]: true, content: true };
      this.articleitemClass = { "articleList-item": true, [name1]: true };
      this.titleClass = { title: true, [name2]: true };
      this.assistClass = { assist: true };
      this.authorClass = { author: true };
      this.othertitleClass = { "other-title-v": true, [name2]: true };
    },
  },
  mounted() {
    this.loadData(); //加载初始化
  },
  methods: {

    sendVipQue() {
      const options = {
        method: "POST",
        url: this.$api.sendVipQue,
        data: { userId: this.$route.query.userId },
        // data: {userId: query.userId} //参数替换
      };
      this.$http(options).then((res) => {
        console.log(res);
        Toast(res.data.msg);
        if (res.data.code === 200) {
          setTimeout(() => {
            this.loadData();
          }, 1000);
        }
      });
    },
    loadData() {
      console.log("初始化请求数据");

      // let atoken = this.$route.query.token;
      // let token = this.base64_decode(atoken);
      // sessionStorage.setItem("token",token); // 存放token
      // console.log("token",token);
      let id = this.$route.query.id;
      let userid = this.$route.query.userId;

      let query = "?id=" + id
      console.log(query);
      const options = {
        method: "GET",
        url: this.$api.mgetHtml + query
      };
      this.$http(options)
        .then((res) => {
          console.log("html阅读", res);
          if (res.data.code === 200) {
            this.loading = false;
            this.data = res.data.data || {};
            if (res.data.data.isNew == 1) {
              //isNew  1: 是  (第一次) 0: 否
              this.isNew = true;
            }
          } else if (res.data.code === 667) {
            // this.showVip = true
            // this.tipMsg = res.data.msg
            Dialog.confirm({
              message: res.data.msg,
              confirmButtonText: "申请会员",
              showCancelButton: false,
              beforeClose: (action, done) => {
                this.sendVipQue();
                done();
              },
            });
          } else if (res.data.code === 660) {
            console.log("未注册用户");
            this.isRegister = true;
          } else {
            Dialog({
              message: res.data.msg,
              showConfirmButton: false,
              showCancelButton: false,
            });
          }
        })
        .catch((res) => {
          console.log("error data::", res);
          this.loading = true;
        });
    },
    longpress() {
      // Toast("长按");
      this.settingShow = true; //设置字体显示
    },
    clickWrap() {
      this.settingShow = false; //设置字体隐藏
    },
    sliderChange(e) {
      this.textFont = Math.round(e); //四舍五入取整
    },
    settingType(e) {
      this.setType = e;
    },
    setBg(e) {
      this.bgColor = e;
    },
    toggleBg() {
      console.log("aaa");
      this.bgColor = this.bgColor === "black" ? "white" : "black";
    },
    //收藏
    addFavorite() {
      let id = this.$route.query.id;
      let userid = this.$route.query.userId;
      let type = ~~this.data.isCollect == 1 ? 0 : 1;
      // let query = "?articleId="+id+"&userId="+userid+"&type="+type
      const options = {
        method: "POST",
        url: this.$api.setCollect,
        data: { articleId: id, userId: userid, type: type },
      };
      this.$http(options).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.data.isCollect = ~~this.data.isCollect === 1 ? 0 : 1;
          let msg = type === 1 ? "已收藏" : "已取消";
          Toast(msg);
        } else {
          Toast(res.data.msg);
        }
      });
    },
    postShraMessage() {
      let query = "type=html&id=" + this.$route.query.id;
      wx.miniProgram.postMessage({
        title: this.data.author,
        desc: this.data.title,
        path: "/pages/HTMLreader/index" + query,
      });
    },
    //关闭弹窗
    ruleDesHiddenAction() {
      console.log("点击了关闭");
      var that = this;
      that.isNew = false;
      // that.loadData();
    },
    //相关文章跳转
    clinkLook(article) {
      let id = article.id;
      let userid = this.$route.query.userId;
      let query = "?id=" + id + "&userId=" + userid;
      console.log(query);
      const options = {
        method: "GET",
        url: this.$api.getHtml + query,
      };
      this.$http(options)
        .then((res) => {
          console.log("html阅读", res);
          if (res.data.code === 200) {
            if (res.data.data != "") {
              this.loading = false; //有数据则隐藏掉
              window.scrollTo(0, 0); //回到顶部
              this.data = res.data.data || {};
            } else {
              this.loading = true; //有数据则隐藏掉
            }
          } else if (res.data.code === 667) {
            // this.showVip = true
            // this.tipMsg = res.data.msg
            Dialog.confirm({
              message: res.data.msg,
              confirmButtonText: "申请会员",
              showCancelButton: false,
              beforeClose: (action, done) => {
                this.sendVipQue();
                done();
              },
            });
          } else {
            Dialog({
              message: res.data.msg,
              showConfirmButton: false,
              showCancelButton: false,
            });
          }
        })
        .catch((res) => {
          console.log("error data::", res);
          this.loading = true; //无数据则显示
        });
    },
  },
};
</script>

<style scoped>
@import "../assets/iconfont/font_set_read_bar/iconfont.css";
.wrap {
  padding: 20px;
  /* padding-top: 100px; html高度过大 3/4 qylupdata */
  padding-bottom: 50px;
}
.content {
  letter-spacing: 1.5px;
}
.assist {
  font-size: 14px;
  line-height: 30px;
}
.author {
  font-size: 12px;
  line-height: 40px;
}
.line-black {
  background-color: #969696;
  min-height: 1px;
}
.bg-black {
  background-color: #505050;
  color: #969696;
}
.line-red {
  background-color: #ff3e3e;
  min-height: 1px;
}
.line-palegreen {
  background-color: #7e7e7e;
  min-height: 1px;
}
.bg-palegreen {
  background-color: #d8ffbd;
  color: #000000;
}
.line-coral {
  background-color: #8d4b00;
  min-height: 1px;
}
.bg-coral {
  background-color: #ffcd94;
  color: #000000;
}
.line-white {
  background-color: #dadada;
  min-height: 1px;
}
.bg-white {
  background-color: #ffffff;
  color: #292929;
}
/deep/ .van-overlay {
  background: none !important;
  height: 0px !important;
}
.font-10 {
  font-size: 10px;
  line-height: 16px;
}
.font-11 {
  font-size: 11px;
  line-height: 18px;
}
.font-12 {
  font-size: 12px;
  line-height: 20px;
}
.font-13 {
  font-size: 12px;
  line-height: 22px;
}
.font-14 {
  font-size: 14px;
  line-height: 24px;
}
.font-15 {
  font-size: 15px;
  line-height: 26px;
}
.font-16 {
  font-size: 16px;
  line-height: 28px;
}
.font-17 {
  font-size: 17px;
  line-height: 28px;
}
.qyl_font16 {
  font-size: 16px;
  line-height: 40px;
}
.font-18 {
  font-size: 18px;
  line-height: 30px;
}
.font-19 {
  font-size: 18px;
  line-height: 30px;
}
.font-20 {
  font-size: 20px;
  line-height: 30px;
}
.font-21 {
  font-size: 20px;
  line-height: 30px;
}
.font-22 {
  font-size: 22px;
  line-height: 30px;
}
.font-23 {
  font-size: 22px;
  line-height: 30px;
}
.font-24 {
  font-size: 24px;
  line-height: 30px;
}

.other-title-v {
  display: flex;
  margin: 10px 0px;
}
.other-icon {
  width: 2%;
  margin: 4px 6px;
  margin-left: 0px;
  border-radius: 2px;
}
.articleList-item {
  width: 100%;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.van-popup {
  min-height: 8%;
  /* box-shadow: 0px -2px 10px #ececec; */
}
.popup-mask {
  background: none !important;
  height: 0px !important;
}
.set-bar {
  display: flex;
  flex: 1;
  height: 50px;
  align-items: center;
  justify-content: space-around;
}
.set-font-v {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  margin-bottom: 20px;
  min-height: 50px;
}
.van-slider {
  margin: 0px 10px;
}
.zi-add::after {
  content: "-";
}
.zi-sub::after {
  content: "+";
}
.color-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px dashed #000000;
  margin: 15px;
}
figure {
  display: block;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

/* >>>这两行代码 用来修改手机端的图片大小显示 */

.contentClass >>> figure {
  display: block;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
  overflow: auto;
}

.contentClass >>> img {
  width: 100%;
  margin: auto;
  object-fit: fill;
}
.widthImg >>> img {
  width: 100%;
  margin: auto;
  /* width: auto\9; */
  object-fit: fill;
}
.fixed_box {
  z-index: 999;
  text-align: -webkit-center;
}
.box_center {
  text-align: -webkit-center;
  /* width:100px;
  height: 60px;
  display: block;
  text-align: center; */
}
.color66 {
  color: #66ccff;
}
/* 活动规则弹框 */
.rulePopView {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 999;
}
.ruleDesView {
  top: 20%;
  right: 15%;
  left: 15%;
  height: 50%;
  opacity: 1;
  background-color: white;
  position: fixed;
  width: 70%;
  border-radius: 0.1rem;
}
.ruleDesImage {
  width: 100%;
  height: 100%;
}
.cancelView {
  border-radius: 0.1rem;
  top: 65%;
  right: 46%;
  left: 46%;
  position: absolute;
}
.cancelImage {
  width: 100%;
  height: 100%;
}
.tipTitle {
  text-align: center;
  font-size: 0.35rem;
  height: 1rem;
  line-height: 1rem;
  font-weight: bold;
  background-color: #58aced;
  color: #ffffff;
}
.tipNav {
  padding: 5%;
  font-size: 0.5rem;
}
.textcenter {
  font-size: 0.4rem;
  margin-top: 0.1rem;
}
.margin20 {
  margin-top: 20px;
}
image {
  overflow: visible;
}
/* 富文本样式 */
/* .ql-editor{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    line-height: 1.42;
    height: 100%;
    outline: none;
    padding: 0 !important;
    -o-tab-size: 4;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
    word-wrap: break-word;
} */
</style>
