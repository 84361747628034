import Vue from "vue";
import axios from "axios";

const BASE_URL = "https://api.ccenews.com.cn";
const API = {
    //阅读文章
   getHtml: "/article/app/info",
   //图文
   getPdf: "/periodical/app/info",
   //视频
   wxServer: "/api/app/signature",
   //收藏/取消收藏
   setCollect: "/article/app/collect",
   //会员审批申请
   sendVipQue: "/apply/app/save",
   //免登陆阅读文章
   mgetHtml: "/article/app/info2",
   //免登录图文
   mgetPdf: "/periodical/app/info2"
};
const config = {
  API,
  BASE_URL
};

// 添加请求拦截器
const service = axios.create({
  baseURL: config.BASE_URL,
  timeout: 30000
});
service.interceptors.request.use(
  config => {
    // 设置请求头信息
	let token = sessionStorage.getItem("token");
	if(token){
		config.headers.Authorization = "Bearer " +  sessionStorage.getItem("token") || "";
	}

    // config.headers['Content-Type'] = 'text/plain' // 关键所在
    
    // if (config.method === "post") {
    //   config.data = Qs.stringify(config.data);
    // }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
// service.interceptors.response.use(
//   response => {
//     // 保存响应头返回的token
//     console.log("a", response);
//     if (response.data.data && response.data.data.token) {
//       sessionStorage.setItem("token", response.data.data.token);
//     }
//     return response;
//   },
//   error => {
//     // 对响应错误做统一处理
//     console.log("response error", error);
//     // 如果登录过期就重新登录
//     if (error.message === "Request failed with status code 401") {
//       Notification.warning({
//         message: "登录超时，请重新登录"
//       });
//       sessionStorage.clear();
//       router.replace("/login");
//     }else{
//       Notification.warning({
//         message: "网络错误"
//       });
//       router.replace("/login");
//     }
//     return Promise.reject(error);
//   }
// );

export default {
  service,
  BASE_URL,
  API
};
