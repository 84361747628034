import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { Loading } from 'vant';
import http from './http/index'
import 'lib-flexible'
import './directive' //自定义指令

//视图导入
import AliPay from './view/aliPay.vue'
import htmlReader from './view/htmlReader.vue'
import pdfReader from './view/pdfReader.vue'
import htmlReader2 from './view/htmlReader_token.vue'
import pdfReader2 from './view/pdfReader_token.vue'

//富文本的css
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
  


Vue.use(Loading);
Vue.use(VueQuillEditor);
//使用路由
Vue.use(VueRouter);
//定义路由
const routes = [
  { path: '/', component: AliPay },
  { path: '/html', component: htmlReader },
  { path: '/pdf', component: pdfReader },
  { path: '/html2', component: htmlReader2 },
  { path: '/pdf2', component: pdfReader2 },
]
//创建 router 实例
const router = new VueRouter({
  // mode: "history",
  routes // (缩写) 相当于 routes: routes
})


Vue.config.productionTip = false
Vue.prototype.$http = http.service;
Vue.prototype.$api = http.API;
Vue.prototype.$BASE = http.BASE_URL;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
