<template>
  <div class="wrap">您的系统版本过低,请升级版本</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wrap{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>